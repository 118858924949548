var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Sha256 } from "@aws-crypto/sha256-js";
import { HttpRequest } from "@smithy/protocol-http";
import { SignatureV4 } from "@smithy/signature-v4";
import { ApiError } from "@app-stack/api_client/src/errors";
import { InvokeCommand } from "@aws-sdk/client-lambda";
import { LambdaInvokeError } from "@app-stack/types/errors";
export class BaseApiClient {
    constructor(url, region, httpClient, credentialProvider, lambdaClient) {
        this.httpClient = httpClient;
        this.apiBaseUrl = url;
        this.requestSigner = new SignatureV4({
            credentials: credentialProvider,
            region: region,
            service: "execute-api",
            sha256: Sha256,
        });
        this.lambdaClient = lambdaClient;
    }
    constructHttpRequest(method, path, body) {
        const apiUrl = new URL(path, this.apiBaseUrl);
        const reqOptions = {
            method: method,
            headers: {
                accept: "*/*",
                host: apiUrl.hostname,
            },
            hostname: apiUrl.hostname,
            path: apiUrl.pathname,
            body: body ? JSON.stringify(body) : undefined,
        };
        if (body) {
            reqOptions.headers["Content-Type"] = "application/json";
        }
        return new HttpRequest(reqOptions);
    }
    logError(message, error) {
        console.error(`${message}: ${error.message}`);
        console.error(error.stack);
    }
    _invokeLambda(functionName, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this._doLambdaInvoke(functionName, payload);
            if (!response) {
                throw new LambdaInvokeError("Lambda function returned empty payload when one was expected.");
            }
            const responsePayload = response.transformToString();
            console.log("Lambda response payload", responsePayload);
            const parsedPayload = JSON.parse(responsePayload);
            return JSON.parse(parsedPayload.body);
        });
    }
    _invokeLambdaVoid(functionName, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this._doLambdaInvoke(functionName, payload);
        });
    }
    _doLambdaInvoke(functionName, payload) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.lambdaClient) {
                throw new Error("Lambda client not initialized");
            }
            const command = new InvokeCommand({
                FunctionName: functionName,
                Payload: JSON.stringify(payload),
            });
            const response = yield this.lambdaClient.send(command);
            if (response.StatusCode === 200) {
                return response.Payload;
            }
            else {
                throw new LambdaInvokeError(`Lambda function ${functionName} returned status code ${response.StatusCode} and error ${response.FunctionError}`);
            }
        });
    }
    _sendRequest(obj) {
        return __awaiter(this, void 0, void 0, function* () {
            const signedRequest = yield this.requestSigner.sign(obj);
            const response = yield this.httpClient.request({
                method: signedRequest.method,
                url: `${this.apiBaseUrl}${signedRequest.path}`,
                headers: signedRequest.headers,
                data: signedRequest.body,
            });
            console.log("API request returned with: " + response.status + "-" + response.statusText);
            if (response.status < 400) {
                if (response.data && typeof response.data === "string") {
                    return JSON.parse(response.data);
                }
                else {
                    return response.data;
                }
            }
            else {
                let message = response.statusText;
                if (response.data && typeof response.data === "string") {
                    try {
                        const json = JSON.parse(response.data);
                        if (json.message) {
                            message = json.message;
                        }
                    }
                    catch (e) {
                        message = response.data;
                    }
                }
                throw new ApiError(response.status, message);
            }
        });
    }
}
