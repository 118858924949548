import { Suspense, lazy, useEffect } from "react";
import { Loader } from "components";
import { Authenticator } from "@aws-amplify/ui-react";
import {
  Navigate,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import { Hub, fetchAuthSession } from "@aws-amplify/core";
import { getCurrentUser } from "aws-amplify/auth";
import * as Sentry from "@sentry/react";
import { setupAmplify } from "auth/amplify_setup";
import { SignedInUser } from "hooks";
import { formatUserGroups } from "utils";
import { ProtectedRoute, GroupRouter } from "./protected";

const Insights = lazy(() => import("./insights/Insights"));
const Broker = lazy(() => import("./broker/Broker"));
const Portal = lazy(() => import("./portal/Portal"));
const NewInquiry = lazy(() => import("./broker/inquiry/new/NewInquiry"));
const NewInsight = lazy(() => import("./insights/NewInsight"));

Hub.listen("auth", async ({ payload: { event } }) => {
  switch (event) {
    case "signedIn":
      try {
        console.log("event", event);
        const user = await getCurrentUser();
        const authSession = await fetchAuthSession();
        const userGroups = formatUserGroups(
          (authSession?.tokens?.accessToken?.payload["cognito:groups"] as string[]) || [],
        );
        const email = user?.signInDetails?.loginId;
        if (email && typeof email === "string") {
          const signedInUser: SignedInUser = { email, userGroups };
          localStorage.setItem("signedInUser", JSON.stringify(signedInUser));
        } else {
          throw new Error("Email not found in user object");
        }
      } catch (error) {
        console.error("Error fetching authenticated user:", error);
      }
      break;
    case "signedOut":
      localStorage.removeItem("signedInUser");
      console.log("User email cleared from local storage.");
      break;
    case "tokenRefresh_failure":
      localStorage.removeItem("signedInUser");
      console.log("Session expired or token refresh failed, cleared user email.");
      break;
  }
});

Sentry.init({
  dsn: "https://443d75955a8b39f1936813023e1c383f@o4505909308555264.ingest.sentry.io/4505909399191552",
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Disable for local development
  enabled: process.env.NODE_ENV !== "development",
});

export function Root() {
  setupAmplify();
  return (
    <Authenticator>
      <Router>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route
              path="portal/:orgId/*"
              element={
                <Suspense fallback={<Loader />}>
                  <Portal />
                </Suspense>
              }
            />
            <Route
              path="broker/inquiry/:id/*"
              element={
                <Suspense fallback={<Loader />}>
                  <Broker />
                </Suspense>
              }
            />
            <Route
              path="broker/inquiry/new"
              element={
                <Suspense fallback={<Loader />}>
                  <NewInquiry />
                </Suspense>
              }
            />
            <Route path="broker" element={<Navigate to={"inquiry/new"} />} />
          </Route>
          <Route element={<GroupRouter />}>
            <Route element={<ProtectedRoute adminOnly={true} />}>
              <Route
                path="insights/:id/*"
                element={
                  <Suspense fallback={<Loader />}>
                    <Insights />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="insights"
              element={
                <Suspense fallback={<Loader />}>
                  <NewInsight />
                </Suspense>
              }
            />
            <Route
              path="/"
              element={
                <Suspense fallback={<Loader />}>
                  <NewInsight />
                </Suspense>
              }
            />
            <Route path="*" element={<div>Not Found</div>} />
          </Route>
        </Routes>
      </Router>
    </Authenticator>
  );
}
