import { customAlphabet, urlAlphabet } from "nanoid";
import { max, format, isValid, parse, parseISO } from "date-fns";
import { PracticeProfile, Structural, StatusType, Limit, Deductible, DeclinationReason, NoteType, } from "__generated__/graphql";
import { UserGroups, InsightDetailType } from "localTypes";
import { STATE_LICENSE_URLS } from "app-constants";
export function convertClaimDateToClaim(claimDate, npi) {
    const splitDate = claimDate.split("-");
    const incidentDate = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
    return {
        npi,
        incidentDate,
    };
}
export function sortClaimsByIncidentDate(claims) {
    return [...claims].sort((a, b) => {
        const dateA = parseDateString(a.incidentDate);
        const dateB = parseDateString(b.incidentDate);
        if (!dateA && !dateB)
            return 0;
        if (!dateA)
            return 1;
        if (!dateB)
            return -1;
        return dateB.getTime() - dateA.getTime();
    });
}
export function parseDateString(dateString) {
    if (!dateString)
        return null;
    const [month, day, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
}
export function formatDate(dateString) {
    if (!dateString)
        return "";
    if (typeof dateString === "string" &&
        (dateString.includes("ago") || dateString.includes("from now"))) {
        return dateString;
    }
    const isoDate = parseISO(dateString);
    if (isValid(isoDate)) {
        return format(isoDate, "MMM d, yyyy");
    }
    const commonFormats = ["yyyy-MM-dd", "MM/dd/yyyy", "MMM d, yyyy"];
    for (let dateFormat of commonFormats) {
        const parsedDate = parse(dateString, dateFormat, new Date());
        if (isValid(parsedDate)) {
            return format(parsedDate, "MMM d, yyyy");
        }
    }
    return "";
}
export function formatMrsTrends(modelData) {
    var _a, _b, _c, _d, _e, _f;
    return [
        {
            version: "MRS Trend - 2024",
            value: parseFloat(((_b = (_a = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.ADJUSTED_RELATIVE_SCORE_REPORT) || "-1"),
        },
        {
            version: "MRS Trend - 2023",
            value: parseFloat(((_d = (_c = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.ADJUSTED_RELATIVE_SCORE_2023) || "-1"),
        },
        {
            version: "MRS Trend - 2022",
            value: parseFloat(((_f = (_e = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.ADJUSTED_RELATIVE_SCORE_2022) || "-1"),
        },
    ];
}
export function formatBillingData(modelData) {
    var _a, _b, _c, _d, _e;
    const results = (_a = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _a === void 0 ? void 0 : _a[0];
    return {
        payerData: [
            {
                year: "2023",
                amount: results ? `${results.NON_CMS_PAYOR_TOTAL_PAYMENT_1Y_2023}` : "",
            },
            {
                year: "2022",
                amount: results ? `${results.NON_CMS_PAYOR_TOTAL_PAYMENT_1Y_2022}` : "",
            },
            {
                year: "2021",
                amount: results ? `${results.NON_CMS_PAYOR_TOTAL_PAYMENT_1Y_2021}` : "",
            },
        ],
        hvData: [
            {
                year: "2024",
                amount: `${(_c = (_b = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.HV_TOTAL_CLAIM_COUNT_1Y_REPORT}` || "",
            },
            {
                year: "2023",
                amount: `${(_e = (_d = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.HV_TOTAL_CLAIM_COUNT_1Y_2023}` || "",
            },
        ],
    };
}
export function formatUserGroups(groups) {
    if (!groups)
        return [];
    return groups.map((group) => {
        switch (group) {
            case "Underwriters":
                return UserGroups.Underwriters;
            case "Brokers":
                return UserGroups.Brokers;
            default:
                return UserGroups.Unknown;
        }
    });
}
export function generateId() {
    const gen = customAlphabet(urlAlphabet, 7);
    return gen();
}
export const formatAsDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export function formatDeductible(deductible) {
    switch (deductible) {
        case Deductible.None:
            return "None";
        case Deductible["10K"]:
            return "10K";
        case Deductible["25K"]:
            return "25K";
        case Deductible["50K"]:
            return "50K";
        default:
            return "Unknown";
    }
}
export function formatLimit(limit) {
    switch (limit) {
        case Limit["100K_300K"]:
            return "0.1M/0.3M";
        case Limit["200K_600K"]:
            return "0.2M/0.6M";
        case Limit["250K_750K"]:
            return "0.25M/0.75M";
        case Limit["500K_1500K"]:
            return "0.5M/1.5M";
        case Limit["1M_3M"]:
            return "1M/3M";
        case Limit["1_3M_3_9M"]:
            return "1.3M/3.9M";
        case Limit["1M_4M"]:
            return "1M/4M";
        case Limit["1M_5M"]:
            return "1M/5M";
        case Limit["2M_4M"]:
            return "2M/4M";
        case Limit["2M_5M"]:
            return "2M/5M";
        case Limit["2M_6M"]:
            return "2M/6M";
        case Limit["2_3M_6_9M"]:
            return "2.3M/6.9M";
        case Limit["2_65M_7_95M"]:
            return "2.65M/7.95M";
        default:
            return "Unknown";
    }
}
export function formatDeclinationReason(reason) {
    switch (reason) {
        case DeclinationReason.Claims:
            return "Claims";
        case DeclinationReason.MrsScoreTooHigh:
            return "MRS Score Too High";
        case DeclinationReason.SubmittedByAnotherBroker:
            return "Submitted by Another Broker";
        case DeclinationReason.Structural:
            return "Structural";
        case DeclinationReason.BoardActions:
            return "Board Actions";
        case DeclinationReason.PracticeProfile:
            return "Practice Profile";
        default:
            return "Unknown";
    }
}
export function formatPracticeProfileReason(ppReason) {
    switch (ppReason) {
        case PracticeProfile.Semaglutide:
            return "Semaglutides";
        case PracticeProfile.MedSpa:
            return "Med Spa";
        case PracticeProfile.Telehealth:
            return "Telehealth";
        case PracticeProfile.NursingHome:
            return "Nursing Home";
        case PracticeProfile.Other:
            return "Other";
        default:
            return "Unknown";
    }
}
export function formatStructuralReason(sReason) {
    switch (sReason) {
        case Structural.Occurrence:
            return "Occurrence";
        case Structural.Limits:
            return "Limits";
        case Structural.PerVisits:
            return "Per Visists";
        case Structural.Other:
            return "Other";
        default:
            return "Unknown";
    }
}
export function formatStatusType(status) {
    switch (status) {
        case StatusType.ActuaryReview:
            return "Actuary Review";
        case StatusType.AgencyPending:
            return "Agency Pending";
        case StatusType.DsInput:
            return "DS Input";
        case StatusType.InternalDiscussion:
            return "Internal Discussion";
        case StatusType.NeedBrokerInfo:
            return "Need Broker Info";
        case StatusType.StatePending:
            return "State Pending";
        case StatusType.SystemBug:
            return "System Bug";
        case StatusType.UwAnalyzing:
            return "UW Analyzing";
        case StatusType.Ratwip:
            return "RAT WIP";
        case StatusType.RatOfficial:
            return "RAT Official";
        case StatusType.InQueue:
            return "In Queue";
        case StatusType.Declined:
            return "Declined";
        default:
            return "";
    }
}
export function formatLocationInconsistencies(provider, modelData) {
    var _a, _b, _c, _d;
    const { address } = provider;
    const dsCountyName = ((_b = (_a = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.FIPS_COUNTY_NAME_REPORT) || "";
    const dsStateName = ((_d = (_c = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.STATE_REPORT) || "";
    const declaredCounty = (address === null || address === void 0 ? void 0 : address.countyName) || "";
    const declaredState = (address === null || address === void 0 ? void 0 : address.state) || "";
    return [
        {
            ds: `${dsCountyName}, ${dsStateName}`,
            iqvia: "",
            inferred: "",
            declared: `${declaredCounty}, ${declaredState}`,
            isConsistent: dsCountyName.toLowerCase() === declaredCounty.toLowerCase() &&
                dsStateName.toLowerCase() === declaredState.toLowerCase(),
        },
    ];
}
export function extractInferredSpecialties(modelData) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (!modelData)
        return [];
    return [
        ((_b = (_a = modelData.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.INFERRED_SPECIALTY_RANK_1_2023) || "",
        ((_d = (_c = modelData.results) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.INFERRED_SPECIALTY_RANK_2_2023) || "",
        ((_f = (_e = modelData.results) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.INFERRED_SPECIALTY_RANK_3_2023) || "",
        ((_h = (_g = modelData.results) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.INFERRED_SPECIALTY_RANK_4_2023) || "",
        ((_k = (_j = modelData.results) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.INFERRED_SPECIALTY_RANK_5_2023) || "",
    ];
}
const specialtyMap = {
    "Otorhinolaryngology-Surgery": [
        "Otorhinolaryngology-Surgery",
        "Plastic Otorhinolaryngology-Surgery",
    ],
    "Plastic Otorhinolaryngology-Surgery": [
        "Otorhinolaryngology-Surgery",
        "Plastic Otorhinolaryngology-Surgery",
    ],
    Otolaryngology: ["Otolaryngology", "Otorhinolaryngology"],
    Otorhinolaryngology: ["Otolaryngology", "Otorhinolaryngology"],
};
export function formatSpecialtyInconsistencies(provider, modelData, isQuickAuditEnabled) {
    var _a, _b, _c;
    const { nppesSpecialty, indigoSpecialty, manualIndigoSpecialty } = provider;
    const validSpecialty = isQuickAuditEnabled
        ? indigoSpecialty
        : manualIndigoSpecialty || indigoSpecialty;
    const modelRatingSpecialty = (_b = (_a = modelData === null || modelData === void 0 ? void 0 : modelData.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.INDIGO_SPECIALTY_REPORT;
    const inferredSpecialties = extractInferredSpecialties(modelData);
    let inferredFlag = false;
    let inferred = inferredSpecialties[0];
    if (modelRatingSpecialty) {
        inferredFlag = inferredSpecialties.includes(modelRatingSpecialty);
        if (inferredFlag) {
            const index = inferredSpecialties.indexOf(modelRatingSpecialty);
            inferred = inferredSpecialties[index];
        }
    }
    const isConsistent = ((_c = specialtyMap[modelRatingSpecialty || ""]) === null || _c === void 0 ? void 0 : _c.includes(validSpecialty || "")) ||
        modelRatingSpecialty === validSpecialty;
    return [
        {
            nppes: nppesSpecialty || "",
            dhc: "",
            inferred: inferred || "",
            declared: validSpecialty || "",
            ds: modelRatingSpecialty || "",
            isConsistent,
        },
    ];
}
export function downloadDocument(doc) {
    const a = document.createElement("a");
    a.href = doc.downloadUrl;
    a.download = doc.key;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(doc.downloadUrl);
}
export function downloadBugReport(bugData) {
    if (bugData.submissionSnapshot.id === undefined) {
        throw new Error("Submission ID is required to download bug report");
    }
    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(bugData, null, 2)], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `${bugData.submissionSnapshot.id}-bug-report.txt`;
    document.body.appendChild(element);
    element.click();
}
export function formatFileSize(bytes, decimals = 2) {
    var _a;
    if (bytes === 0)
        return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    let unit = (_a = sizes[i]) !== null && _a !== void 0 ? _a : "GB";
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${unit}`;
}
export function addressToString(address) {
    if (!address)
        return "";
    let addressString = "";
    addressString += address.streetName ? `${address.streetName}` : "";
    addressString += address.secondary ? ` ${address.secondary}` : "";
    addressString += address.city ? ` ${address.city},` : ",";
    addressString += address.state ? ` ${address.state}` : "";
    addressString += address.zip ? ` ${address.zip}` : "";
    return addressString.split("  ").join(" ").trim();
}
export function parseCustomerResults(customerResults) {
    return customerResults.map((customer) => ({
        customerId: customer.Result.Customer.CustomerNumber,
        npi: customer.Result.Customer.MyPerson.MyPersonalCustomer.NationalProviderIdentifier,
        firstName: customer.Result.Customer.MyPerson.MyPersonalCustomer.FirstName,
        lastName: customer.Result.Customer.MyPerson.MyPersonalCustomer.LastName,
    }));
}
export function parsePremiumResults(quoteResults) {
    return quoteResults[0].PolicyJSON.ListofCoverages.map((coverage) => ({
        amount: coverage.Premium.Amount,
        customer: coverage.ServiceExternalIdentifier.split(":")[1],
    }));
}
export function addPremiumToCustomer(customers, premiums) {
    return customers.map((customer) => {
        const customerPremiums = premiums.filter((premium) => premium.customer === customer.customerId);
        return Object.assign(Object.assign({}, customer), { premium: customerPremiums.reduce((sum, premium) => sum + premium.amount, 0) });
    });
}
export function formatCustomerPremiums(sunlightResponse) {
    const customers = parseCustomerResults(sunlightResponse.customerResults);
    const premiums = parsePremiumResults(sunlightResponse.quoteResults);
    const customersWithPremiums = addPremiumToCustomer(customers, premiums);
    return customersWithPremiums;
}
export function sumPremiums(quoteResults) {
    var _a, _b, _c;
    return (((_c = (_b = (_a = quoteResults === null || quoteResults === void 0 ? void 0 : quoteResults[0]) === null || _a === void 0 ? void 0 : _a.PolicyJSON) === null || _b === void 0 ? void 0 : _b.ListofCoverages) === null || _c === void 0 ? void 0 : _c.reduce((sum, coverage) => { var _a; return sum + ((_a = coverage.Premium) === null || _a === void 0 ? void 0 : _a.Amount); }, 0)) || 0);
}
export function calcMrsAdjustment(mrs, target) {
    return parseInt(Math.abs(((mrs - target) / mrs) * 100).toFixed(2));
}
export function defaultMrsAdjustment(mrs) {
    if (mrs >= 0.35 && mrs <= 0.5) {
        return 0;
    }
    if (mrs >= 0.372 && mrs <= 1.25) {
        return -8;
    }
    if (mrs > 1.26) {
        return 0;
    }
    if (mrs < 0.372) {
        return calcMrsAdjustment(mrs, 0.35);
    }
}
export function formatStateLicenses(licenses) {
    return licenses.map(({ state, number }) => {
        let unknownState = false;
        if (STATE_LICENSE_URLS[state || "unknown"] === undefined) {
            console.warn(`No license URL for state: ${state}`);
            unknownState = true;
        }
        return {
            state,
            number,
            link: STATE_LICENSE_URLS[unknownState ? "unknown" : state || "unknown"] || {
                name: "",
                url: "",
            },
        };
    });
}
export function countErrors(errors, prevCount = 0, depth = 0) {
    let amountOfErrors = prevCount;
    if (depth > 3)
        return amountOfErrors;
    if (errors) {
        return Object.keys(errors)
            .map((k) => {
            var _a;
            if ((_a = errors[k]) === null || _a === void 0 ? void 0 : _a.type) {
                return 1;
            }
            else {
                return countErrors(errors[k], amountOfErrors, depth + 1);
            }
        })
            .reduce((a, b) => a + b, 0);
    }
    return amountOfErrors;
}
export function formatErrors(errors) {
    const errorCount = countErrors(errors);
    if (errorCount === 1) {
        return "1 Issue";
    }
    else {
        return `${errorCount} Issues`;
    }
}
export const formatDecimal = (num) => {
    if (num === undefined)
        return "00.0";
    const isNegative = num < 0;
    const absNum = Math.abs(num);
    const [integerPart, decimalPart] = absNum.toFixed(1).split(".");
    const formattedIntegerPart = integerPart.length < 2 ? integerPart.padStart(2, "0") : integerPart;
    const result = `${formattedIntegerPart}.${decimalPart}`;
    return isNegative ? `-${result}` : result;
};
export function formatBrokerNote(data) {
    var _a;
    if (data === undefined)
        return undefined;
    const note = {
        id: data === null || data === void 0 ? void 0 : data.id,
        content: data === null || data === void 0 ? void 0 : data.notes,
        author: (_a = data === null || data === void 0 ? void 0 : data.producer) === null || _a === void 0 ? void 0 : _a.email,
        type: NoteType.Broker,
    };
    return note;
}
export function doProviderSpecialtiesMatchManualSpecialties(providers) {
    return providers.every((provider) => provider.manualIndigoSpecialty
        ? provider.indigoSpecialty === provider.manualIndigoSpecialty
        : true);
}
export function shouldResetQuickAudit(dirtyFields) {
    var _a, _b, _c, _d;
    if (((_b = (_a = dirtyFields === null || dirtyFields === void 0 ? void 0 : dirtyFields.providers) === null || _a === void 0 ? void 0 : _a.flatMap((p) => Object.keys(p))) === null || _b === void 0 ? void 0 : _b.includes("indigoSpecialty")) ||
        ((_d = (_c = dirtyFields === null || dirtyFields === void 0 ? void 0 : dirtyFields.providers) === null || _c === void 0 ? void 0 : _c.flatMap((p) => Object.keys(p))) === null || _d === void 0 ? void 0 : _d.includes("newToPractice"))) {
        return true;
    }
    return false;
}
export function generateFactors(data) {
    var _a;
    const factors = [];
    if (data) {
        const d = (_a = data === null || data === void 0 ? void 0 : data.results) === null || _a === void 0 ? void 0 : _a[0];
        if (d) {
            for (let i = 1; i <= 10; i++) {
                if (i === 1 ||
                    i === 2 ||
                    i === 3 ||
                    i === 4 ||
                    i === 5 ||
                    i === 6 ||
                    i === 7 ||
                    i === 8 ||
                    i === 9 ||
                    i === 10) {
                    factors.push({
                        name: d[`POSITIVE_FACTOR_${i}`] || "",
                        category: d[`POSITIVE_FACTOR_${i}_CATEGORY`] || "",
                        score: `${d[`POSITIVE_FACTOR_${i}_SCORE`] || 0}`,
                        value: d[`POSITIVE_FACTOR_${i}_VALUE`] || "",
                    });
                }
            }
        }
    }
    return factors;
}
export function mapFlagMessageToInsightDetail(message) {
    const flagMap = {
        "Check Board Actions": InsightDetailType.BoardActions,
        "Check Claims": InsightDetailType.Claims,
        "Check Inconsistencies": InsightDetailType.Consistency,
        "Check Red Flags": InsightDetailType.WebSearch,
    };
    const detailType = flagMap[message.text];
    if (!detailType) {
        return null;
    }
    return detailType;
}
export function formatRenewalFlags(appetite) {
    var _a;
    if (appetite === null)
        return [];
    const providerFlags = (_a = appetite === null || appetite === void 0 ? void 0 : appetite.providers) === null || _a === void 0 ? void 0 : _a.map((pf) => {
        return {
            providerId: pf.id,
            flags: pf.messages
                .filter((f) => f.intent !== "success")
                .map(mapFlagMessageToInsightDetail)
                .filter((f) => f !== null),
        };
    });
    return providerFlags || [];
}
export function getDirtyFields(oldObj, newObj) {
    const isObject = (obj) => obj !== null && typeof obj === "object" && !Array.isArray(obj);
    const isArray = (obj) => Array.isArray(obj);
    const isEmpty = (obj) => {
        if (!obj)
            return true;
        if (isArray(obj))
            return obj.length === 0 || obj.every(isEmpty);
        if (isObject(obj))
            return Object.keys(obj).length === 0;
        return false;
    };
    const diff = {};
    const pendingIdChanges = {};
    const allKeys = Object.keys(oldObj)
        .concat(Object.keys(newObj))
        .filter((value, index, self) => self.indexOf(value) === index);
    // First pass: collect all non-id changes
    for (const key of allKeys) {
        if (key === "id") {
            pendingIdChanges[key] = newObj[key];
            continue;
        }
        const oldVal = oldObj[key];
        const newVal = newObj[key];
        // Handle arrays
        if (isArray(oldVal) && isArray(newVal)) {
            const arrayDiff = oldVal
                .map((oldItem, index) => {
                const newItem = newVal[index];
                if (isObject(oldItem) && isObject(newItem)) {
                    return getDirtyFields(oldItem, newItem);
                }
                return oldItem !== newItem ? newItem : undefined;
            })
                .filter((item) => item !== undefined)
                .filter((item) => !isEmpty(item)); // Remove empty objects/arrays
            if (arrayDiff.length > 0) {
                diff[key] = arrayDiff;
            }
            continue;
        }
        // Handle nested objects
        if (isObject(oldVal) && isObject(newVal)) {
            const nestedDiff = getDirtyFields(oldVal, newVal);
            if (Object.keys(nestedDiff).length > 0) {
                diff[key] = nestedDiff;
            }
            continue;
        }
        // Handle primitive values
        if (oldVal !== newVal) {
            diff[key] = newVal;
        }
    }
    // Second pass: only include IDs if there are other changes
    if (Object.keys(diff).length > 0) {
        Object.assign(diff, pendingIdChanges);
    }
    return diff;
}
export const debounce = (func, wait) => {
    let timeout;
    return ((...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    });
};
export function getLatestRetroDate(submission) {
    var _a, _b, _c;
    if (submission.providers) {
        const retroDates = [
            ...(((_a = submission.providers) === null || _a === void 0 ? void 0 : _a.map((p) => p.retroDate || "").filter(Boolean)) || []),
            ...(((_b = submission.midlevels) === null || _b === void 0 ? void 0 : _b.map((m) => m.retroDate || "").filter(Boolean)) || []),
            ...(((_c = submission.entities) === null || _c === void 0 ? void 0 : _c.map((e) => e.retroDate || "").filter(Boolean)) || []),
        ];
        const parsedDates = retroDates.map((date) => parse(date, "MM/dd/yyyy", new Date()));
        if (parsedDates.length === 0) {
            return "";
        }
        const latestDate = max(parsedDates);
        if (latestDate) {
            return format(latestDate, "MM/dd/yyyy");
        }
        return "";
    }
    return "";
}
