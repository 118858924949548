import { Image, Button, Text } from "@chakra-ui/react";
import { IoMdSquare } from "react-icons/io";
import { Link, useParams } from "react-router-dom";
import logo from "assets/white-logo.svg";

export function SideNav() {
  const { orgId } = useParams();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "176px",
        backgroundColor: "#5851b8",
        color: "white",
        fontSize: "1.125rem",
        fontWeight: 700,
        padding: "16px 24px",
        gap: "20px",
      }}
    >
      <Image h="27px" w="98px" src={logo} alt="white Indigo logo" my="20px" />
      <Button
        as={Link}
        to={`/portal/${orgId}/policies`}
        _hover={{ color: "#fbd38d", bg: "transparent" }}
        leftIcon={<IoMdSquare />}
        p="8px 4px"
        variant="ghost"
        color="white"
        fontSize="md"
        fontWeight="bold"
        justifyContent="flext-start"
        alignItems="center"
      >
        <Text mt="-3px">Policies</Text>
      </Button>
      <Button
        as={Link}
        to={`/portal/${orgId}/offices`}
        _hover={{ color: "#fbd38d", bg: "transparent" }}
        leftIcon={<IoMdSquare />}
        p="8px 4px"
        variant="ghost"
        color="white"
        fontSize="md"
        fontWeight="bold"
        justifyContent="flext-start"
      >
        <Text mt="-3px">Offices</Text>
      </Button>
      <Button
        _hover={{ color: "#fbd38d", bg: "transparent" }}
        leftIcon={<IoMdSquare />}
        p="8px 4px"
        variant="ghost"
        color="white"
        fontSize="md"
        fontWeight="bold"
        justifyContent="flext-start"
      >
        <Text mt="-3px">Resources</Text>
      </Button>
    </div>
  );
}
