import { Breadcrumb as ChakraBreadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { ReactElement } from "react";
import { ChevronRightIcon } from "@chakra-ui/icons";

export interface BreadcrumbProps {
  items: ReactElement<typeof BreadcrumbLink>[];
}

export function Breadcrumb({ items }: BreadcrumbProps) {
  return (
    <ChakraBreadcrumb separator={<ChevronRightIcon color="gray.500" />} spacing="4px" fontSize="sm">
      {items.map((item, index) => (
        <BreadcrumbItem color="indigo.500" isCurrentPage={index === items.length - 1} key={index}>
          {item}
        </BreadcrumbItem>
      ))}
    </ChakraBreadcrumb>
  );
}
