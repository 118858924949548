import { Icon } from "@chakra-ui/react";

interface SortIconProps {
  color: string;
  direction?: "asc" | "desc";
}

export function SortIcon({ direction, color }: SortIconProps) {
  return (
    <Icon
      textAlign="center"
      overflow="visible"
      viewBox="0 0 12 12"
      color="indigo.500"
      boxSize="30px"
      backgroundColor="transparent"
    >
      {direction === "desc" ? (
        <svg viewBox="0 0 200 100">
          <path
            d="M20 80 L40 100 L60 80"
            stroke={color}
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <path
            d="M20 40 L40 60 L60 40"
            stroke={color}
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      ) : direction === "asc" ? (
        <svg viewBox="0 0 200 100">
          <path
            d="M20 40 L40 20 L60 40"
            stroke={color}
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <path
            d="M20 80 L40 60 L60 80"
            stroke={color}
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      ) : (
        <svg viewBox="0 0 200 100">
          <path
            d="M20 40 L40 20 L60 40"
            stroke={color}
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          <path
            d="M20 80 L40 100 L60 80"
            stroke={color}
            strokeWidth="12"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      )}
    </Icon>
  );
}
